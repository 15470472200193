<template>
  <div class="modal">
    <div class="modal__mask">
      <div class="modal__wrapper">
        <div v-if="mappingNotEmpty" class="modal__container">
          <div class="modal__header">
            <div class="modal__header-content">
              <div class="report-title">Edit Lifting</div>
              <div class="modal__header-left">
                <div class="report-table__item-text-multiple-choice-wrap" v-if="mappingNotEmpty && localStorageRole == 'Admin'">
                  <div class="switcher-content__data-multiselect">
                    <Multiselect
                      v-model="this.fields[372].value"
                      :options="charterers"
                      label="name" 
                      valueProp="rid" 
                      :searchable="true"
                      placeholder="Charterer - Name"                  
                    />
                  </div>
                </div>
                <div v-if="mappingNotEmpty">
                  <!-- {{ this.fields[42].value }} -->
                  <div
                    class="report-table__item-text-multiple-choice-wrap"
                    v-if="this.objAllFields[42].fieldType == 'text-multiple-choice'"
                  >
                    <template
                      v-if="getTextMultipleChoiceType(this.objAllFields[42]) == 'list'"
                    >
                      <div class="switcher-content__data-multiselect">
                        <Multiselect
                          v-model="this.mapping[6].body[0].body[0][0].value"
                          :options="this.objAllFields[42]['properties']['choices']"
                          v-on:select="this.tabToggle()"
                        >
                          <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                              {{ format42(value.label) }}
                            </div>
                          </template>

                          <template v-slot:option="{ option }">
                            {{ format42(option.label) }}
                          </template>
                        </Multiselect>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="modal__title">
                <div v-if="mappingNotEmpty" :data-rid="this.rid">
                  {{ this.fields[780].value }}
                </div>
              </div>
              <!-- <div class="modal__title" :data-rid="this.rid">{{ modalName }}</div> -->
              <div>
                <!-- <div v-if="mappingNotEmpty">
                  <a target="_blank" :href="this.fields[558].value"
                    >Google Drive</a
                  >
                </div> -->
              </div>
              <div class="modal__header-panel">
                <div
                  class="modal__delete-button"
                  v-on:click="() => this.showDeleteConfirmation()">
                  <span>Delete</span>
                </div>
                <div
                  class="modal__close-button"
                  v-on:click="this.handleCancel"
                >
                  <div class="close-button__img"></div>
                  <span>Cancel</span>
                </div>
                <div class="modal__save-button" v-on:click="() => this.handleSave(false)">
                  <CustomLoader v-if="sendingToQB && !isSubmitAction" />
                  <div v-else class="save-button__body">
                    <div class="save-button__img"></div>
                    <span>Save</span>
                  </div>
                </div>
                <div class="modal__submit-button" v-on:click="() => this.handleSave(true)">
                  <CustomLoader v-if="sendingToQB && isSubmitAction" />
                  <div v-else class="save-button__body">
                    <div class="save-button__img"></div>
                    <span>Submit</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="modal__close"></div> -->
            <!-- <div
              class="modal__close"
              v-on:click="
                () => {
                  this.$emit('showStatus', false);
                }
              "
            ></div> -->
          </div>

          <div class="modal__body">
            <div class="switch-bar modal__switch-bar">
              <template v-for="(tab, index) in mapping" :key="index">
                <div
                  class="switch-bar__item"
                  v-bind:class="{
                    'switch-bar__item_active': index == selectedSwitcher,
                  }"
                  v-on:click="handleClickTab(index)"
                  v-if="isShowTab(tab)"
                >
                  <template v-if="tab.title == 'Freight Fixtures'">
                    {{ tab.title }}
                    <span v-if="requiredFields.some(field => !this.fields[field]?.value)" class="required-fields-star">*</span>
                  </template>
                  <template v-else>
                    {{ tab.title }} 
                  </template>
                </div>
              </template>
              <div class="switch-bar__item google-drive" v-if="mappingNotEmpty">
                <div class="google-drive__left-img"></div>
                <a target="_blank" :href="this.fields[558].value"
                  >Google Drive</a
                >
              </div>
              <!-- <div class="switch-bar__item switch-bar__item_active">General</div> -->
              <!-- <div class="switch-bar__item">Freight</div>
                <div class="switch-bar__item">Reminders</div> -->
            </div>
            <div class="switcher-content">
              <div v-if="mappingNotEmpty" class="switcher-content__choose">
                <div
                  class="switcher-content__sub-tab"
                  v-for="(subTab, subTabIndex) in mapping[selectedSwitcher][
                    'body'
                  ]"
                  :key="subTabIndex"
                >
                  <div
                    class="switcher-content__sub-tab-title"
                    v-if="subTab.showTitle"
                  >
                    {{ subTab.title }}
                  </div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr
                        v-for="(row, rowIndex) in subTab.body"
                        :key="rowIndex"
                      >
                        <template
                          v-for="(value, valueIndex) in row"
                          :key="valueIndex"
                        >
                          <!-- <td v-if="isShowElement(value)" style="word-wrap: break-word;">{{
                                  this.objAllFields[value.key].fieldType !=
                                  "url"
                                    ? this.objAllFields[value.key].label
                                    : ""
                                }}</td> -->
                          <td
                            v-if="showField(value) && canShowFieldForTab(subTab.title, value) && canShowShipFieldForTab(value)"
                            :colspan="valueIndex == row.length - 1
                              ? subTab.title === 'Stow' || subTab.title === 'Estimated Intake & Pre-Stow'
                                ? 1
                                : value.hasOwnProperty('colspan')
                                  ? value.colspan
                                  : 100
                              : 1
                            "
                          >
                            <div v-if="this.getTypeForElement(value) == ''">

                              <div
                                class="field-title"
                                v-if="value.key == 107"
                              >
                              
                                <div v-if="this.fields[958].value.length >= 1" class="btn-log field-title__btn-log" @click="(e) => {}">
                                  <span>!</span>
                                  <div class="btn-log__body">
                                    <div>
                                      <div :key="index" v-for="(value, index) in this.sortLogs(this.fields[958].value)">
                                        <div>{{ value }}</div>
                                        <div>--------------------</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <span>{{ this.objAllFields[value.key].label }}</span>
                              </div>
                              <div
                                class="field-title"
                                v-else-if="value.key == 106"
                              >
                              
                                <div v-if="this.fields[957].value.length >= 1" class="btn-log field-title__btn-log" @click="(e) => {}">
                                  <span>!</span>
                                  <div class="btn-log__body">
                                    <div>
                                      <div :key="index" v-for="(value, index) in this.sortLogs(this.fields[957].value)">
                                        <div>{{ value }}</div>
                                        <div>--------------------</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <span>{{ this.objAllFields[value.key].label }}</span>
                              </div>
                              <div
                                class="field-title"
                                v-if="value.key == 1226"
                              >
                                {{ this.getLabel(value) }}
                              </div>
                              <div
                                class="field-title"
                                v-if="value.key == 349 || value.key == 359 || value.key == 348 || value.key == 360"
                              >
                                {{ this.getLabel(value) }}
                                <span v-if="!this.fields[value.key]?.value" class="required-fields-star">*</span>
                              </div>
                              <div v-else class="field-title">
                                {{
                                  this.objAllFields[value.key].fieldType !=
                                  "url"
                                    ? this.getLabel(value) //this.objAllFields[value.key].label // + " " + value.key + " | " + this.objAllFields[value.key].fieldType  + " | " + this.objAllFields[value.key].mode
                                    // ? this.getLabel(value) + " " + value.key //this.objAllFields[value.key].label // + " " + value.key + " | " + this.objAllFields[value.key].fieldType  + " | " + this.objAllFields[value.key].mode
                                    : ""
                                }}
                              </div>
                              
                              <div
                                v-if="
                                  this.objAllFields[value.key].mode == 'lookup'
                                "
                              >
                                <div
                                  v-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'currency'
                                  "
                                >
                                  <QCurrencyInput
                                    v-model="value.value"
                                    :options="{ currency: 'USD', precision: 2 }"
                                  />
                                </div>
                                <div
                                  v-else-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'timestamp'
                                  "
                                  class="switcher-content__data-multiselect"
                                >
                                  <DatePicker
                                    :format="(t) => formatDateTimeFunc(t)"
                                    :previewFormat="
                                      (t) => formatDateTimeFunc(t)
                                    "
                                    v-model="value.value"
                                    :enableTimePicker="true"
                                    :value-type="valueType.date2value"
                                  />
                                </div>
                                <input
                                  v-else
                                  v-model="value.value"
                                  disabled
                                  type="text"
                                  class="switcher-content__data-input disabled"
                                />
                              </div>
                              <div v-else-if="value.key == 404">
                                <div
                                  @click="
                                    () =>
                                      openPopUpForAddDocumentTypeCnf(
                                        'Sale: Cnf'
                                      )
                                  "
                                  class="url-btn"
                                  style="
                                    background-color: #deecfc;
                                    color: black;
                                    background-image: linear-gradient(
                                      to bottom,
                                      #deecfc 0%,
                                      #deecfc 100%
                                    );
                                  "
                                >
                                  {{
                                    this.objAllFields[value.key].properties
                                      .appearsAs
                                  }}
                                </div>
                              </div>
                              <!-- <div v-else-if="value.key == 405">
                                <div
                                  @click="
                                    () =>
                                      openPopUpForAddDocumentTypeFob(
                                        'Sale: Fob'
                                      )
                                  "
                                  class="url-btn"
                                  style="
                                    background-color: #b9ceeb;
                                    color: black;
                                    background-image: linear-gradient(
                                      to bottom,
                                      #b9ceeb 0%,
                                      #b9ceeb 100%
                                    );
                                  "
                                >
                                  {{
                                    this.objAllFields[value.key].properties
                                      .appearsAs
                                  }}
                                </div>
                              </div> -->
                              <!-- <div v-else-if="value.key == 406">
                                <div
                                  @click="
                                    () =>
                                      openPopUpForAddDocumentTypeCnf(
                                        'Purchase: Cnf'
                                      )
                                  "
                                  class="url-btn"
                                  style="
                                    background-color: #c3b4d2;
                                    color: black;
                                    background-image: linear-gradient(
                                      to bottom,
                                      #c3b4d2 0%,
                                      #c3b4d2 100%
                                    );
                                  "
                                >
                                  {{
                                    this.objAllFields[value.key].properties
                                      .appearsAs
                                  }}
                                </div>
                              </div> -->
                              <div v-else-if="value.key == 407">
                                <div
                                  @click="
                                    () =>
                                      openPopUpForAddDocumentTypeFob(
                                        'Purchase: Fob'
                                      )
                                  "
                                  class="url-btn"
                                  style="
                                    background-color: #9bb460;
                                    color: black;
                                    background-image: linear-gradient(
                                      to bottom,
                                      #9bb460 0%,
                                      #9bb460 100%
                                    );
                                  "
                                >
                                  {{
                                    this.objAllFields[value.key].properties
                                      .appearsAs
                                  }}
                                </div>
                              </div>

                              <div
                                v-else-if="value.key == 249"
                                class="rich-text__249 qb-update-eta-button"
                              >
                                <div
                                  :data-rid="this.fields[248].value"
                                  v-html="value.value"
                                ></div>
                              </div>

                              <div
                                v-else-if="value.key == 349 || value.key == 359 || value.key == 348 || value.key == 360"
                                class="required-fields"
                              >
                                <input
                                  v-model="value.value"
                                  type="number"
                                  class="switcher-content__data-input"
                                  @input="handleRequiredFields(value.key)"
                                />
                                <span v-if="!value.value && value.value !== 0 && !this[`field${value.key}`]" class="message-text">This field is required</span>
                              </div>

                              <div
                                v-else-if="value.key == 636"
                                class="
                                  rich-text__249
                                  qb-update-complete-current-task-button
                                "
                              >
                                <div
                                  :data-rid="this.fields[248].value"
                                  v-html="value.value"
                                ></div>
                              </div>

                              <div
                                v-else-if="value.key == 252"
                                class="rich-text__249 qb-update-etc-button"
                              >
                                <div
                                  :data-rid="this.fields[251].value"
                                  v-html="value.value"
                                ></div>
                              </div>

                              <div
                                v-else-if="value.key == 484"
                                class="rich-text__484"
                              >
                                <div v-html="value.value"></div>
                              </div>

                              <div
                                style="height: 40px"
                                :data-row="index"
                                :data-fid="item"
                                v-else-if="
                                  this.objAllFields[value.key].fieldType ==
                                  'rich-text'
                                "
                                v-html="value.value"
                              ></div>

                              <div
                                v-else-if="
                                  this.objAllFields[value.key].mode == 'formula'
                                "
                              >
                                <div
                                  v-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'url'
                                  "
                                >
                                  <a :href="value.value" class="url-btn">{{
                                    this.objAllFields[value.key].properties
                                      .appearsAs
                                  }}</a>
                                </div>
                                <textarea
                                  v-else-if="value.key == 703"
                                  style="display: block; min-width: 500px"
                                  v-model="value.value"
                                  class="
                                    switcher-content__text-data-textarea
                                    disabled
                                  "
                                  disabled
                                >
                                </textarea>
                                <textarea
                                  v-else-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'text'
                                  "
                                  style="display: block"
                                  v-model="value.value"
                                  class="
                                    switcher-content__text-data-textarea
                                    disabled
                                  "
                                  disabled
                                >
                                </textarea>
                                <div
                                  v-else-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'timestamp'
                                  "
                                  class="switcher-content__data-multiselect"
                                >
                                  <DatePicker
                                    :format="(t) => formatDateTimeFunc(t)"
                                    :previewFormat="
                                      (t) => formatDateTimeFunc(t)
                                    "
                                    v-model="value.value"
                                    :enableTimePicker="true"
                                    :value-type="valueType.date2value"
                                    :disabled="true"
                                  />
                                </div>
                                <div
                                  v-else-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'currency'
                                  "
                                >
                                  <QCurrencyInput
                                    v-model="value.value"
                                    :options="{ currency: 'USD', precision: 2 }"
                                    :disabled="true"
                                  />
                                </div>
                                <input
                                    v-else-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'numeric'
                                  "
                                    v-model="value.value"
                                    type="number"
                                    class="switcher-content__data-input"
                                />
                                <input
                                  v-else
                                  v-model="value.value"
                                  disabled
                                  type="text"
                                  class="switcher-content__data-input disabled"
                                />
                              </div>

                              <div
                                v-else-if="
                                  this.objAllFields[value.key].mode == 'summary'
                                "
                              >
                                <div
                                  v-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'currency'
                                  "
                                >
                                  <QCurrencyInput
                                    v-model="value.value"
                                    :options="{ currency: 'USD', precision: 2 }"
                                    :disabled="true"
                                  />
                                </div>
                                <div
                                  v-else-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'timestamp'
                                  "
                                  class="switcher-content__data-multiselect"
                                >
                                  <DatePicker
                                    :format="(t) => formatDateTimeFunc(t)"
                                    :previewFormat="
                                      (t) => formatDateTimeFunc(t)
                                    "
                                    v-model="value.value"
                                    :value-type="valueType.date2value"
                                    :disabled="true"
                                    :hideInputIcon="true"
                                    inputClassName="dp-custom-input"
                                  />
                                </div>
                                <input
                                  v-else
                                  v-model="value.value"
                                  disabled
                                  type="text"
                                  class="switcher-content__data-input disabled"
                                />
                              </div>
                              <div
                                v-else-if="
                                  this.objAllFields[value.key].fieldType ==
                                  'checkbox'
                                "
                              >
                                <input
                                  v-model="value.value"
                                  type="checkbox"
                                  class="switcher-content__data-input checkbox"
                                />
                              </div>

                              <div
                                v-else-if="
                                  this.objAllFields[value.key].fieldType ==
                                  'percent'
                                "
                              >
                                <percent-input v-model="value.value" />
                              </div>

                              <div
                                  v-else-if="(value.key == 1200 || value.key == 1199)"
                              >
                                <textarea
                                    style="display: block; min-width: 500px"
                                    v-model="value.value"
                                    class="
                                    switcher-content__text-data-textarea
                                    disabled
                                  "
                                    disabled
                                ></textarea>

                              </div>

                              <div
                                v-else-if="
                                  this.objAllFields[value.key].fieldType ==
                                  'text-multi-line'
                                "
                              >
                                <textarea
                                  v-model="value.value"
                                  name=""
                                  id=""
                                  cols="30"
                                  rows="1"
                                  class="switcher-content__text-data-textarea"
                                ></textarea>
                              </div>
                              <div
                                v-else-if="
                                  this.objAllFields[value.key].fieldType ==
                                  'date'
                                "
                                class="switcher-content__data-multiselect"
                              >
                                <DatePicker
                                  v-if="value.key == 1222"
                                  :format="(t) => formatDateFunc(t)"
                                  :previewFormat="(t) => formatDateFunc(t)"
                                  v-model="value.value"
                                  :enableTimePicker="false"
                                  :disabled="true"
                                />
                                <!-- <input
                                  v-if="value.key == 1222"
                                  v-model="this.mapping[3].body[0].body[1][1].value"
                                  type="text"
                                  class="switcher-content__data-input"
                                /> -->
                                <DatePicker
                                  v-else
                                  :format="(t, a) => formatDateFunc(t, value.value)"
                                  v-model="value.value"
                                  :enableTimePicker="false"
                                />
                              </div>

                              <div
                                v-else-if="
                                  this.objAllFields[value.key].fieldType ==
                                  'timestamp'
                                "
                                class="switcher-content__data-multiselect"
                              >
                                <DatePicker
                                  :format="(t) => formatDateTimeFunc(t)"
                                  :previewFormat="(t) => formatDateTimeFunc(t)"
                                  v-model="value.value"
                                  :enableTimePicker="true"
                                  :value-type="valueType.date2value"
                                />
                              </div>

                              <div
                                class="
                                  report-table__item-text-multiple-choice-wrap
                                "
                                v-else-if="
                                  this.objAllFields[value.key].fieldType ==
                                  'text-multiple-choice'
                                "
                              >
                                <template
                                  v-if="
                                    getTextMultipleChoiceType(
                                      this.objAllFields[value.key]
                                    ) == 'list'
                                  "
                                >
                                  <div class="switcher-content__data-multiselect">
                                    <Multiselect
                                      v-model="value.value"
                                      :options="
                                        this.objAllFields[value.key][
                                          'properties'
                                        ]['choices'].sort()
                                      "
                                    />
                                  </div>
                                </template>
                                <template
                                  v-if="
                                    getTextMultipleChoiceType(
                                      this.objAllFields[value.key]
                                    ) == 'table'
                                  "
                                >
                                  <div class="switcher-content__data-multiselect">
                                    <Multiselect
                                      v-model="value.value"
                                      :options="
                                        getDataForDropDownField(
                                          this.objAllFields[value.key][
                                            'properties'
                                          ]['masterChoiceTableId'],
                                          this.objAllFields[value.key][
                                            'properties'
                                          ]['masterChoiceFieldId']
                                        ).sort()
                                      "
                                    />
                                  </div>
                                </template>
                                <!-- <template v-else>
                                  <div>{{ value.value }}222222</div>
                                </template> -->
                                <!-- <Multiselect 
                              v-model="value.value"
                              :options="
                                this.objAllFields[value.key].properties.choices
                              "
                              clearOnSelect="false"
                              placeholder="Choose"
                              :close-on-select="false"
                            ></Multiselect> -->
                              </div>

                              <div
                                v-else-if="
                                  this.objAllFields[value.key].fieldType ==
                                  'currency'
                                "
                              >
                                <QCurrencyInput
                                  v-if="value.key == 766"
                                  v-model="value.value"
                                  :options="{ currency: 'USD', precision: 2 }"
                                  :disabled="false"
                                  @input="handleInitialFreightInput"
                                />
                                <QCurrencyInput
                                  v-else
                                  v-model="value.value"
                                  :options="{ currency: 'USD', precision: 2 }"
                                  :disabled="false"
                                />
                              </div>

                              <div v-else-if="value.key == 10">
                                <input
                                  v-model="value.value"
                                  disabled
                                  type="text"
                                  class="switcher-content__data-input"
                                />
                              </div>
                              <div v-else-if="
                                    value.key === 1176
                                  ">
                                <input
                                    v-model="value.value"
                                    @input="resultYear"
                                    type="number"
                                    class="switcher-content__data-input"
                                />
                              </div>

                              <div v-else-if="value.key === 1226" class="google-docs-wrap">
                                <div class="switcher-content__data-multiselect">
                                  <Multiselect
                                    v-model="this.mapping[3].body[0].body[2][1].value"
                                    :options="allDocuments"
                                    label="name"
                                    valueProp="previewUrl"
                                    :searchable="true"
                                    placeholder="PDF Preview"
                                  ></Multiselect>
                                </div>
                                <div class="docs-preview" v-if="this.mapping[3].body[0].body[2][1].value">
                                  <iframe
                                    :src="this.mapping[3].body[0].body[2][1].value"
                                    allowfullscreen
                                  ></iframe>
                                </div>
                              </div>

                              <div v-else-if="
                                  this.objAllFields[value.key].fieldType ==
                                  'numeric'
                                ">
                                <input
                                    v-model="value.value"
                                    type="number"
                                    class="switcher-content__data-input"
                                />
                              </div>
                              <div v-else>
                                <input
                                    v-model="value.value"
                                    type="text"
                                    class="switcher-content__data-input"
                                />
                              </div>
                            </div>
                            <div
                              v-else-if="
                                this.getTypeForElement(value) ==
                                'documentFilter'
                              " style="display:flex"
                              class="switcher-content__data-multiselect-document-filter"
                            >
                              <Multiselect
                                  key="documentFilter"
                                  v-model="documentReportAllModel"
                                  :options="documentReportAllModelOptions"
                                  :searchable="true"
                                  :multiple="true"
                                  mode="tags"
                                  label="name"
                                  trackBy="rid"
                                  track-by="rid"
                                  valueProp="key"
                                ></Multiselect>

                                <div class="btn-apply">
                                  <div class="btn-apply__body" v-on:click="filterApply">
                                    <custom-loader v-if="filterApplying" />
                                    <span v-else>Apply</span>
                                  </div>
                                </div>
                            </div>
                            
                            <div class="document-report-wrap"
                              v-else-if="
                                this.getTypeForElement(value) ==
                                'holidaysReport'
                              "
                            >
                              <HolidaysReport
                                :allFields=this.allHolidayFields
                                :clist=this.holidayClist
                                :reportDataOriginal=this.holidayReportData
                                :countryFilterValue=getCountryFilter(value)
                                :stateFilterValue=getStateFilter(value)
                                :startHolidayDateFilterValue=getStartDateValue(value)
                                :endHolidayDateFilterValue=getEndDateValue(value)
                                :idsForShowFields=[6,9]
                              />
                            </div>
                            <div class="document-report-wrap"
                              v-else-if="
                                this.getTypeForElement(value) ==
                                'documentReportAll'
                              "
                            >
                              <QbDocumentReportAll
                                key="documentReportAll"
                                :table="'bsdk56xut'"
                                :report="1"
                                :filter="{}"
                                :filterReport=documentReportAllModel
                                @setOptions=setDocumentReportAllModelOptions
                              />
                            </div>
                            <div
                              v-else-if="
                                this.getTypeForElement(value) ==
                                'documentReport15'
                              "
                            >
                              <QbDocumentReport
                                :table="'bsdk56xut'"
                                :report="15"
                                :filter="{ 7: [this.rid] }"
                              />
                            </div>
                            <div
                              v-else-if="
                                this.getTypeForElement(value) ==
                                'documentReport16'
                              "
                            >
                              <QbDocumentReport
                                :table="'bsdk56xut'"
                                :report="16"
                                :filter="{ 7: [this.rid] }"
                              />
                            </div>
                            <div class="document-report-wrap"
                              v-else-if="
                                this.getTypeForElement(value) ==
                                'documentReport20'
                              "
                            >
                              <QbDocumentReport
                                :table="'bsdk56xut'"
                                :report="20"
                                :filter="{ 7: [this.rid] }"
                              />
                            </div>
                            <div
                              v-else-if="
                                this.getTypeForElement(value) == 'googleMap'
                              "
                            >
                              <GoogleMap v-if="showGoogleMap()"
                                api-key="AIzaSyBCq7eDRFf_9uHGo1Tl4RLm9AGgelhqQqw"
                                style="width: 100%; height: 500px; min-width: 700px"
                                :center="getGoogleMapCenter()"
                                :zoom="7"
                                :minZoom="2"
                              >
                                <Polyline v-for="(value, valueIndex) in this.shipPaths"
                                  :key="valueIndex"
                                  :options="getShipPath(valueIndex)"
                                />
                              
                                <Marker v-if="!!this.getGoogleMapMarker()"
                                  :options="{
                                    position: getGoogleMapMarker(),
                                    icon: markerIcon,
                                  }"
                                />
                              </GoogleMap>
                            </div>
                            <div
                              v-else-if="
                                this.getTypeForElement(value) ==
                                'text'
                              " style="width: 250px"
                            >
                              <span>{{value.value}}</span>
                            </div>
                            
                            <div
                              v-else-if="
                                this.getTypeForElement(value) ==
                                'multichoice-rel'
                              "
                            >
                              <div class="switcher-content__title">
                                {{
                                  this.objAllFields[value.labelId].fieldType !=
                                  "url"
                                    ? this.objAllFields[value.labelId].label // + " " + value.key + " | " + this.objAllFields[value.key].fieldType  + " | " + this.objAllFields[value.key].mode
                                    : ""
                                }}
                              </div>
                              <div class="switcher-content__data-multiselect">
                                <Multiselect
                                  v-if="value.key == 95"
                                  class="vessel-name"
                                  v-model="value.value"
                                  :options="sortOptionsById(tablesOprions[value.tableId], value.nameId)"
                                  :label="value.nameId"
                                  :track-by="value.nameId"
                                  :searchable="true"
                                >
                                  <template #option="{ option }">
                                    <div class="vessel-fields-wrap">
                                      <span class="vessel-field-6">{{ option['6'] }}</span>
                                      <span class="vessel-field-43">{{ option['43'] }}</span>
                                      <span class="vessel-field-30">{{ option['30'] }}</span>
                                    </div>
                                  </template>
                                </Multiselect>
                                <Multiselect
                                  v-else
                                  v-model="value.value"
                                  :options="sortOptionsById(tablesOprions[value.tableId], value.nameId)"
                                  :label="value.nameId"
                                  :track-by="value.nameId"
                                  :searchable="true"
                                />
                              </div>
                              <!-- :searchStart="true" -->

                              <!-- </Multiselect> -->

                              <!-- <multiselect v-model="value" :options="options" :custom-label="nameWithLang" placeholder="Select one" label="name" track-by="name"></multiselect> -->
                            </div>
                          </td>
                          <td :colspan="100" class="wrap-new-ship-button" v-if="value.labelId == 542 || value.labelId == 507">
                            <div>
                              <button
                                  class="new-ship-button"
                                  @click="toggleHolds(subTab.title)"
                              >
                                {{ showHolds[subTab.title] ? 'Hide Holds' : 'Show Holds' }}
                              </button>
                            </div>
                          </td>
                          <td :colspan="1" class="wrap-new-ship-button" v-if="value.key == 1171 && shouldShowButton()">
                            <div>
                              <button
                                  class="new-ship-button"
                                  @click="toggleShip()"
                              >
                                {{ showShipFields ? 'New Ship' : 'Cancel Ship' }}
                              </button>
                            </div>
                          </td>
                        </template>
                      </tr>
                    </table>
                  </div>
                </div>
                <!-- <div class="switcher-content__title">{{ value }}</div> -->
                <!-- <div class="switcher-content__title">
                    {{ this.objAllFields[value.key].label }}
                  </div> -->
              </div>
            </div>
            <!-- <table class="report-table">
                <tbody>
                  <tr v-for="(value, index) in parentReportData" :key="index">
                    <td>
                      <b>{{ this.objParentFields[index].label }}</b>
                    </td>
                    <td>
                      <textarea :value="value.value"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table> -->
          </div>

          <div class="modal__footer">
            
          </div>
        </div>
        <div v-else  class="modal__container-loader">
          <CustomLoader/>
        </div>
      </div>
    </div>

    <DeleteConfirmation
      :on-confirm="confirmDelete"
      :on-cancel="cancelDelete"
      :is-report="false"
      v-if="showConfirmation"
    />

    <DeleteRecordPopUp
      :record-deleted="recordDeleted"
      record-name="Lifting"
      v-if="showDeleteRecordPopUp"
    />

    <AddDocumentTypeCnfPopUp
      v-if="showModalAddDocumentTypeCnf"
      :open="showModalAddDocumentTypeCnf"
      @showStatus="showStatusAddDocumentTypeCnfPopUp"
      :rid="this.rid"
      :type="this.type"
      :charterer="this.charterer"
    />

    <AddDocumentTypeFobPopUp
      v-if="showModalAddDocumentTypeFob"
      :open="showModalAddDocumentTypeFob"
      @showStatus="showStatusAddDocumentTypeFobPopUp"
      :rid="this.rid"
      :type="this.type"
      :charterer="this.charterer"
    />
    <UpdateRecordPopUp
        v-if="showUpdateRecordPopUp"
        :open="showUpdateRecordPopUp"
        :updatedData="this.updatedData"
        :recordName="this.recordName"
    />

    <RequiredFieldsPopUp
      v-if="showRequiredFieldsPopUp"
    />

  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import moment from "moment";
import CustomLoader from "@/components/loader/CustomLoader.vue";
import AddDocumentTypeCnfPopUp from "@/components/pop-up/AddDocumentTypeCnfPopUpNew.vue";
import AddDocumentTypeFobPopUp from "@/components/pop-up/AddDocumentTypeFobPopUpNew.vue";
import QbDocumentReport from "@/components/report/QbDocumentReport.vue";
import QbDocumentReportAll from "@/components/report/QbDocumentReportAll.vue";
// import { reactive } from "@vue/reactivity";
import {reactive} from "vue";
import QCurrencyInput from "@/components/QCurrencyInput.vue";
import PercentInput from "@/components/PercentInput.vue";
import {GoogleMap, Marker, Polyline} from "vue3-google-map"; //
import QuickbaseRepository from "@/api/Quickbase";
import LiftingRepository from "@/api/Lifting";

import {mapState} from "vuex";

import HolidaysReport from "./HolidaysReport.vue";
import liftingFieldsModel  from "@/model/liftingFields";
import CharterersRepository from "@/api/Charterers";
import UserRepository from "@/api/User";
import ownerRepository from "@/api/tradingPlatform/owner";
import DeleteConfirmation from "@/components/pop-up/DeleteConfirmation.vue";
import DeleteRecordPopUp from "@/components/pop-up/DeleteRecordPopUp.vue";
import UpdateRecordPopUp from "@/components/pop-up/UpdateRecordPopUp.vue";
import RequiredFieldsPopUp from "@/components/pop-up/RequiredFieldsPopUp.vue";

export default {
  name: "EditReportRecord",
  components: {
    Multiselect,
    CustomLoader,
    AddDocumentTypeCnfPopUp,
    AddDocumentTypeFobPopUp,
    QbDocumentReport,
    QCurrencyInput,
    QbDocumentReportAll,
    PercentInput,
    GoogleMap,
    Marker,
    Polyline,
    HolidaysReport,
    DeleteConfirmation,
    DeleteRecordPopUp,
    UpdateRecordPopUp,
    RequiredFieldsPopUp,
  },
  props: {
    // table: String, // delete
    // selectedReport: String, // delete
    open: Boolean, // delete
    rid: Number,
  },
  emits: ["showStatus", "updateRecord"],
  setup() {
    let dataForDropDownFields = reactive({
      bsghxwya8: {},
      br6kf4scx: {},
      br3isn8nb: {},
      br3isji2e: {},
      brx74qi6y: {},
      brxi28a76: {},
      bsud3n4i3: {},
      bsuecfn4c: {},
    });
    return {
      dataForDropDownFields,
    };
  },
  data: function () {
    // setup () {
    // const dataForDropDownFields = reactive({});
    return {
      // dataForDropDownFields: {},
      // dataForDropDownFields,
      ttttt: liftingFieldsModel.fieldsMap,
      testModel: [],
      testLabel: "test label",

      charterer: null,

      documentReportAllModel: [],
      documentReportAllModelOptions: [],
      documentReportAllModelOptions2: [
        {name: "test", rid: 1},
        {name: "test2", rid: 2},
      ],
      filterApplying: false,

      clistForTables: {
        brxis6w8r: [3, 6, 43, 30],
        brxixht2m: [3, 6],
        bs7zzssmr: [3, 6],
        brx74qi6y: [3, 9],
        brxi28a76: [3, 6],
        br3isji2e: [3, 6],
        br3isn8nb: [3, 6],
      },
      tablesOprions: {
        brxis6w8r: [],
        brxixht2m: [],
        bs7zzssmr: [],
        brx74qi6y: [],
        brxi28a76: [],
        br3isji2e: [],
        br3isn8nb: [],
      },
      reports: [],
      modalName: "Lifting Summary", // +  # " + this.rid,
      allFields: [],
      fields: [],
      masterTableId: "brxisk55z",
      isInitialFreightSet: false,
      isSubmitAction: null,
      sendingToQB: false,
      showHolds: {
        'Estimated Intake & Pre-Stow': false,
        'Stow': false,
      }, // string: bool
      showShipFields: true,

      selectedSwitcher: 0,

      showModalAddDocumentTypeCnf: false,
      showModalAddDocumentTypeFob: false,

      type: "",

      mapping: [],

      shipPaths: [],
      charterers: [],
      localStorageRole: "",

      owners: [],
      showConfirmation: false,
      recordDeleted: false,
      showDeleteRecordPopUp: false,
      showUpdateRecordPopUp: false,
      updatedData: false,
      recordName: "Lifting",
      googleDriveId: "",
      allDocuments: [],
      requiredFields: [349, 359, 348, 360],
      showRequiredFieldsPopUp: false,
      field349: true,
      field359: true,
      field348: true,
      field360: true,
    };
  },
  mounted() {
    this.getCharterers();
    this.getDataTaskFields();
    this.getData();
    this.getRelData();
    this.getOwners();
    this.getGoogleDriveId();
  },
  updated() {
    let qbUpdateEtaButton = this.$el.querySelectorAll(
      ".qb-update-eta-button .Vibrant"
    );
    qbUpdateEtaButton.forEach((el) => {
      el.onclick = () => {
        console.log("test qb-update-eta-button");
        console.log(this);
        // let rowId = e.target.parentElement.dataset.row;
        // this.ridForAddTask = this.reportData[rowId][3].value;
        // let rid = this.reportData[rowId][3].value;
        // let fid = e.target.parentElement.dataset.fid;
        this.updateETALoadPort(this.rid);
        return false;
      };
    });

    let qbUpdateEtcButton = this.$el.querySelectorAll(
      ".qb-update-etc-button .Vibrant"
    );
    qbUpdateEtcButton.forEach((el) => {
      el.onclick = () => {
        console.log("test qb-update-etc-button");
        console.log(this);
        // let rowId = e.target.parentElement.dataset.row;
        // this.ridForAddTask = this.reportData[rowId][3].value;
        // let rid = this.reportData[rowId][3].value;
        // let fid = e.target.parentElement.dataset.fid;
        this.updateETCLoadPort(this.rid);
        return false;
      };
    });

    let qbUpdateCompleteCurrentTaskButton = this.$el.querySelectorAll(
      ".qb-update-complete-current-task-button .Vibrant"
    );
    qbUpdateCompleteCurrentTaskButton.forEach((el) => {
      el.onclick = () => {
        console.log("test qb-update-complete-current-task-button");
        console.log(this);
        // let rowId = e.target.parentElement.dataset.row;
        // this.ridForAddTask = this.reportData[rowId][3].value;
        // let rid = this.reportData[rowId][3].value;
        // console.log(rid);
        // let fid = e.target.parentElement.dataset.fid;
        this.updateCompleteCurrentTask(this.rid);
        return false;
      };
    });
    /* let allAddTaskBtn = document.querySelectorAll(".rich-text__249 .Vibrant");
    allAddTaskBtn.forEach((el) => {
      el.onclick = (e) => {
        let rid = e.target.parentElement.dataset.rid;
        let prop = {};
        prop["to"] = "br3qsnsv3";
        prop["data"] = [];

        let dataTmp = {};
        dataTmp["key"] = rid;
        dataTmp[22] = moment().format("YYYY-MM-DDTkk:mm:ssZ"); // m-d-Y g:i A

        prop["data"].push(dataTmp);
        console.log(prop);
        this.saveDataForQBWithoutClosePopUp(prop);
        return false;
      };
    }); */

    let allContracts = document.querySelectorAll(".rich-text__484 a");
    allContracts.forEach((el) => {
      el.onclick = () => {
        return false;
      };
    });

    var textarea = document.querySelectorAll(
      ".switcher-content__text-data-textarea"
    );
    textarea.forEach((el) => {
      console.log("WWWWWWWW");
      console.dir(el);
      console.log(el.value);
      console.log(el.scrollTop);
      console.log(el.scrollHeight);
      console.log(el.scrollWidth);

      el.style.height = 0;

      el.dataset.scrollTop = el.scrollTop;
      el.dataset.scrollHeight = el.scrollHeight;
      el.dataset.scrollWidth = el.scrollWidth;

      if (!el.dataset.newHeight) {
        el.dataset.newHeight = el.scrollHeight + 5 + "px";
      }
      if (!el.dataset.newWidth) {
        el.dataset.newWidth = el.scrollWidth + 20 + "px";
      }
      // if(el.scrollTop > 0){
      // el.style.height = el.scrollHeight + "px";
      // el.style.width = el.scrollWidth + "px";
      el.style.height = el.dataset.newHeight;
      el.style.width = el.dataset.newWidth;
      // }
    });

    /* textarea.addEventListener('keyup', function(){
      if(this.scrollTop > 0){
        this.style.height = this.scrollHeight + "px";
      }
    }); */
  },
  computed: {
    ...mapState({
       allHolidayFields: state => state.holidays.holidayRecords,
       holidayClist: state => state.holidays.holidayClist,
       holidayReportData: state => state.holidays.holidayReportData,
    }),

    getCountryDischargeFilter() {
      return [this.fields[968].value];
    },
    getCountryLoadFilter() {
      return [this.fields[969].value];
    },
    getStateDischargeFilter() {
      return [this.fields[974].value, ""];
    },
    getStateLoadFilter() {
      return [this.fields[973].value, ""];
    },

    /* :allFields=this.allHolidayFields
    :clist=this.holidayClist
    :reportData=this.holidayReportData */

    mappingNotEmpty() {
      if (this.allFields.length == 0) return false;
      return true;
    },
    objAllFields() {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      this.allFields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },
    valueType() {
      return {
        value2date: (value) => {
          // transform the binding value to calendar Date Object
          console.log("value2date", value);

          return moment(value).format("LL");
        },
        date2value: (date) => {
          // transform the calendar Date Object to binding value
          console.log("date2value", date);

          return moment(date).format("LL");
        },
      };
    },
  },
  watch: {
    fields() {
      for (const key in this.mapping) {
        if (Object.hasOwnProperty.call(this.mapping, key)) {
          console.log(this.mapping[key]);
          if (this.isShowTab(this.mapping[key])) {
            this.selectedSwitcher = key;
            break;
          }
        }
      }
    },
  },
  methods: {
    handleRequiredFields(valueKey) {
      this[`field${valueKey}`] = true;
    },

    handleClickTab(index) {
      this.selectedSwitcher = index;
      this.scrollToTop();
    },

    scrollToTop() {
      const scrollContainer = document.querySelector('.switcher-content');
      scrollContainer.scrollTo({ top: 0, behavior: 'smooth' });
    },

    handleCancel() {
      this.$router.push("/").then(() => {
        window.location.reload();
      });
    },

    getCharterers() {
      CharterersRepository.getCharterers()
        .then((result) => {
          this.charterers = result["data"]["data"];
          console.log("Charterers", this.charterers);

          this.getLocalStorageRole()
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },

    getLocalStorageRole() {
      this.localStorageRole = localStorage.role;

      if (this.localStorageRole !== 'Admin') {
        this.getDefaultCharterer();
      }
    },

    getDefaultCharterer() {
      UserRepository.getUsers()
        .then((result) => {
          console.log("Users", result["data"]["data"])

          const default_charterer_id = result["data"]["data"].find(item => item.email === localStorage.email)?.default_charterer || null;

          if (default_charterer_id) {
            this.charterer = this.charterers.find(item => item.id === default_charterer_id)?.rid;
          }

        })
        .catch((error) => {
          console.log("Error", error);
        });
    },

    showDeleteConfirmation() {
      this.showConfirmation = true;
    },

    cancelDelete() {
      this.showConfirmation = false;
    },

    async confirmDelete() {
      console.log("RID", this.rid)

      this.showConfirmation = false; 
    
      LiftingRepository.deleteLifting(this.rid)
        .then((result) => {
          console.log("deleted Lifting", result);

          this.recordDeleted = true
          this.showDeleteRecordPopUp = true

          setTimeout(() => {
            this.showDeleteRecordPopUp = false

            this.$router.push({name: 'main'}).then(() => {
              window.location.reload();
            });
          }, 3000);
        })
        .catch((error) => {
          this.recordDeleted = false
          this.showDeleteRecordPopUp = true

          setTimeout(() => {
            this.showDeleteRecordPopUp = false
          }, 3000);

          console.log("Error", error);
        });
    },

    sortLogs(logs) {
      let newLogs = [];
      for (const key in logs) {
        let element = logs[key];
        let d = element.split("|");
        newLogs.push(
          {
            title: d[0],
            id: d[1],
          }
        );
      }

      // newLogs = newLogs.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
      newLogs = newLogs.sort((a,b) => b.id - a.id)
      return newLogs.map(a => a.title);
    },

    getCountryFilter(value) {
      return value.type == 'load' ? this.getCountryLoadFilter : this.getCountryDischargeFilter;
    },
    getStateFilter(value) {
      return value.type == 'load' ? this.getStateLoadFilter : this.getStateDischargeFilter;
    },
    sortOptionsById(options, id) {
      const sorter = (a, b) => {
        const nameA = a[id].toUpperCase(); // ignore upper and lowercase
        const nameB = b[id].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      };
      return options.sort(sorter);
    },
    filterApply() {
      if(this.documentReportAllModel.length > 0) {
        this.filterApplying = true;
        var requestOptions = {
          method: "POST",
          redirect: "follow",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            documents: this.documentReportAllModel,
            token: localStorage.token,
          }),
        };

        fetch(
          process.env.VUE_APP_API_DOMAIN + "/v1/qb/lifting/" + this.rid + "/documents/bind",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            this.documentReportAllModel = [];
            this.filterApplying = false;
          })
          .catch((error) => {
            console.log("error", error);
            this.documentReportAllModel = [];
            this.filterApplying = false;
          });
        
      }
    },
    setDocumentReportAllModelOptions(oprions) {
      this.documentReportAllModelOptions = [];
      oprions.forEach(e => {
        this.documentReportAllModelOptions.push(e);
      });
    },
    getLabel(value) {
      if (value.hasOwnProperty.call(value, "label")) {
        return value.label;
      } else {
        return this.objAllFields[value.key].label;
      }
    },
    format42(value) {
      return value.slice(2);
    },
    print(value) {
      console.log("Pring+++");
      console.log(value);
      return value;
    },
    recalculateHeightAndWidthForTextArea() {
      var textarea = document.querySelectorAll(
        ".switcher-content__text-data-textarea"
      );
      textarea.forEach((el) => {
        console.log("WWWWWWWW");
        console.dir(el);
        console.log(el.value);
        console.log(el.scrollTop);
        console.log(el.scrollHeight);
        console.log(el.scrollWidth);
        el.style.height = 0;

        el.dataset.scrollTop = el.scrollTop;
        el.dataset.scrollHeight = el.scrollHeight;
        el.dataset.scrollWidth = el.scrollWidth;

        el.dataset.newHeight = el.scrollHeight + "px";
        el.dataset.newWidth = el.scrollWidth + "px";

        /* if (!el.dataset.newHeight) {
        el.dataset.newHeight = el.scrollHeight + "px";
      }
      if (!el.dataset.newWidth) {
        el.dataset.newWidth = el.scrollWidth + 10 + "px";
      } */
        // if(el.scrollTop > 0){
        // el.style.height = el.scrollHeight + "px";
        // el.style.width = el.scrollWidth + "px";
        el.style.height = el.dataset.newHeight;
        el.style.width = el.dataset.newWidth;
        // }
      });
    },
    async getDataForDropDownFields() {
      let info = {};
      for (const key in this.objAllFields) {
        if (Object.hasOwnProperty.call(this.objAllFields, key)) {
          const element = this.objAllFields[key];
          if (
            element["fieldType"] == "text-multiple-choice" &&
            Object.hasOwnProperty.call(
              element["properties"],
              "masterChoiceTableId"
            )
          ) {
            let masterChoiceTableId =
              element["properties"]["masterChoiceTableId"];
            let masterChoiceFieldId =
              element["properties"]["masterChoiceFieldId"];

            if (Object.hasOwnProperty.call(info, masterChoiceTableId)) {
              if (!info[masterChoiceTableId].includes(masterChoiceFieldId)) {
                info[masterChoiceTableId].push(masterChoiceFieldId);
              }
            } else {
              info[masterChoiceTableId] = [masterChoiceFieldId];
            }
            // info.push({fid: this.objAllFields["properties"]["masterChoiceFieldId"], table: this.objAllFields["properties"]["masterChoiceTableId"]});
          }
        }
      }
      for (const key in info) {
        if (Object.hasOwnProperty.call(info, key)) {
          const element = info[key];
          this.getDataForDropDownFieldsFromQB(key, element);
        }
      }
    },
    async getDataForDropDownFieldsFromQB(table, clist) {
      QuickbaseRepository.getRecordsByTableID(table, clist)
        .then((result) => {
          console.log(result);
          let data = result["data"]["body"]["data"];
          this.dataForDropDownFields[table] = data;
          // this.dataForDropDownFields[table].push({});
          // let data = result["body"];
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getDataForDropDownField(table, fid) {
      console.log("getDataForDropDownField table:" + table + " fid:" + fid);
      let result = [];
      if (Object.hasOwnProperty.call(this.dataForDropDownFields, table)) {
        const data = this.dataForDropDownFields[table];
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            const record = data[key];
            for (const fieldId in record) {
              if (Object.hasOwnProperty.call(record, fieldId)) {
                const element = record[fieldId];
                if (fieldId == fid) {
                  result.push(element["value"]);
                }
              }
            }
          }
        }
      }
      return result;
    },
    getTextMultipleChoiceType(prop) {
      if (Object.hasOwnProperty.call(prop["properties"], "choices")) {
        return "list";
      } else if (
        Object.hasOwnProperty.call(prop["properties"], "masterChoiceTableId")
      ) {
        return "table";
      } else {
        return "other";
      }
    },
    async getRelData() {
      for (const key in this.clistForTables) {
        if(key == "brxixht2m") continue;
        if(key == "bs7zzssmr") continue;
        if (Object.hasOwnProperty.call(this.clistForTables, key)) {
          this.getRelDataQB(key, this.clistForTables[key]);
        }
      }
    },

    async getOwners() {
      ownerRepository.getOwnersByUserForCreateLifting().then((result) => {
          console.log(result);
          let data = result["data"]["data"];
          console.log(data);
          data.forEach(element => {
            this.owners.push({
              value: element.rid,
              name: element.name,
            })
            this.tablesOprions["bs7zzssmr"].push(
              {
                "value": element.rid,
                // 3: element.rid,
                6: element.name,
              }
            )
            
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    async getRelDataQB(tableId, clist) {
      QuickbaseRepository.getRecordsByTableID(tableId, clist)
        // .then((response) => response.json())
        .then((result) => {
          console.log(result);
          let data = result["data"]["body"]["data"];
          // let data = result["body"];
          console.log(data);
          let newRes = [];
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              const element = data[key];

              let tmp = {};
              for (const k in element) {
                if (Object.hasOwnProperty.call(element, k)) {
                  if (k == 3) {
                    tmp["value"] = element[k].value;
                  } else {
                    tmp[k] = element[k].value;
                  }
                }
              }
              newRes.push(tmp);
            }
          }

          this.tablesOprions[tableId] = newRes;
        })
        .catch((error) => {
          console.log("error", error);
          this.gettingReport = false;
        });
    },
    getTypeForElement(value) {
      if (Object.hasOwnProperty.call(value, "componentType")) {
        return value["componentType"];
      } else {
        return "";
      }
    },
    isShowElement(value) {
      if (Object.hasOwnProperty.call(value, "componentType")) {
        if (value["componentType"] == "documentReport15") {
          if (
            Object.hasOwnProperty.call(this.fields, 469) &&
            this.fields[469]["value"]
          )
            return true;
          else return false;
        }
        if (value["componentType"] == "documentReport16") {
          if (
            Object.hasOwnProperty.call(this.fields, 470) &&
            this.fields[470]["value"]
          )
            return true;
          else return false;
        }
      }
      return true;
    },
    isShowTab(value) {
      // if(this.fields.length == 0) return true;
      // "Nomination", "Freight Fixtures", "Ports", "Accounting", "Laytime", "Commodity Contract", "Dates", "", ""

      switch (value.id) {
        case 1:
          if (
            this.fields[42]["value"] == "1. Short" ||
            this.fields[42]["value"] == "2. TBN"
          ) {
            // 1 4
            return false;
          } else {
            return true;
          }
          // break;
        case 4:
          if (
            this.fields[42]["value"] == "1. Short" ||
            this.fields[42]["value"] == "2. TBN"
          ) {
            // 1 4
            return false;
          } else {
            return true;
          }
          // break;
        default:
          break;
      }
      if(value.show == false) return false;

      return true;
    },

    formatDateFunc(date, a) {
      // console.log("+++formatDateFunc+++")
      console.log(a);
      // console.log(date)
      // console.log(date.toUTCString())
      // console.log(date.toISOString())
      // console.log( moment(date).tz("US/Pacific").toString())
      if (date) {
        return moment(date).format("MM-DD-YYYY"); //.tz("US/Pacific")
      } else {
        return "";
      }
    },

    formatDateTimeFunc(date) {
      if (date) {
        return (
          moment(date)
            // .tz("US/Pacific")
            .format("MM-DD-yyyy h:mm a")
        ); // America/New_York
      } else {
        return "";
      }
    },

    updateValuesForFields() {
      this.mapping.forEach((tabs) => {
        tabs.body.forEach((subTubs) => {
          subTubs.body.forEach((row) => {
            row.forEach((field) => {
              if (
                Object.hasOwnProperty.call(field, "key") &&
                !Object.hasOwnProperty.call(field, "forFieldId")
              ) {
                this.fields[field.key]["value"] = field.value;
                // console.log(field.key + " =|= " + field.value);
              } else if(Object.hasOwnProperty.call(field, "forFieldId")) {
                this.fields[field.forFieldId]["value"] = field.value;
              }
            });
          });
        });
      });
    },

    isEmpty(str) {
      if (str == "" || str == null || str === 0) return true;
      return false;
    },

    showFieldWithCustomId(key) {
      switch (key) {
        // rule #33
          case "852_hr":
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case "837_hr":
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          default:
            break;
      }
      return true;

    },

    showField(record) {
      console.log("=== showField ===");
      // console.log(record);
      this.updateValuesForFields();
      // let res = true;
      // 103, 526
      let key = record.key;

      if (Object.hasOwnProperty.call(record, "customId")) {
        return this.showFieldWithCustomId(record.customId);
      }

      if (Object.hasOwnProperty.call(record, "labelId")) {
        key = record.labelId;
      }
      // console.log(key);

      try {
        switch (key) {
          // this field needs for google docs
          case 1225:
            return false;

          case 542:
            if (
              this.isEmpty(this.fields[526]["value"]) &&
              !this.isEmpty(this.fields[104]["value"])
            )
              return false;
            else return true;

          case 526:
            if (
              this.isEmpty(this.fields[526]["value"]) &&
              !this.isEmpty(this.fields[104]["value"])
            )
              return false;
            else return true;


          // rule #32
          case 494:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;

          /* case 523:
            if (this.fields[754]["value"] == true) return false;
            else return true;
          case 538:
            if (this.fields[754]["value"] == true) return false;
            else return true;
          case 522:
            if (this.fields[754]["value"] == true) return false;
            else return true;
          case 540:
            if (this.fields[754]["value"] == true) return false;
            else return true;

          case 103:
            if (this.fields[333]["value"] != "") return false;
            else return true;
          case 507:
            if (this.fields[333]["value"] != "") return false;
            else return true; */

          // rule #1
          // rule #5
          // rule #7
          // rule #9
          // rule #10
          case 466:
            if (this.fields[469]["value"] == true) return true;
            else return false;

          // rule #11
          case 467:
            if (this.fields[470]["value"] == true) return true;
            else return false;

          // rule #12
          case 368:
            if (this.fields[472]["value"] == true) return true;
            else return false;
          case 378:
            if (this.fields[472]["value"] == true) return true;
            else return false;
          case 473:
            if (this.fields[472]["value"] == true) return true;
            else return false;
          case 366:
            if (this.fields[472]["value"] == true) return true;
            else return false;

          // rule #13
          case 367:
            if (this.fields[473]["value"] == true) return true;
            else return false;
          case 369:
            if (this.fields[473]["value"] == true) return true;
            else return false;
          case 381:
            if (this.fields[473]["value"] == true) return true;
            else return false;

          // rule #14
          case 327:
            if (this.fields[475]["value"] == true) return true;
            else return false;
          case 500:
            if (this.fields[475]["value"] == true) return true;
            else return false;

          // rule #15
          case 475:
            if (this.fields[474]["value"] == true) return true;
            else return false;
          case 325:
            if (this.fields[474]["value"] == true) return true;
            else return false;
          case 498:
            if (this.fields[474]["value"] == true) return true;
            else return false;

          // rule #16
          case 597:
            if (this.fields[339]["value"] == "FOB") return false;
            else return true;
          case 596:
            if (this.fields[339]["value"] == "FOB") return false;
            else return true;
          case 600:
            if (
              this.fields[339]["value"] == "FOB" ||
              this.fields[748]["value"] == true /* rule #19 */
            )
              return false;
            else return true;
          case 741:
            if (this.fields[339]["value"] == "FOB") return false;
            else return true;
          case 742:
            if (this.fields[339]["value"] == "FOB") return false;
            else return true;
          case 743:
            if (this.fields[339]["value"] == "FOB") return false;
            else return true;

          // rule #17
          case 744:
            if (this.fields[746]["value"] == true) return false;
            else return true;
          case 717:
            if (this.fields[746]["value"] == true) return false;
            else return true;

          // rule #18
          case 740:
            if (this.fields[749]["value"] == true) return false;
            else return true;
          case 716:
            if (this.fields[749]["value"] == true) return false;
            else return true;

          // rule #19
          case 745:
            if (this.fields[748]["value"] == true) return false;
            else return true;
          /* case 600:
              if(this.fields[748]["value"] == true) return false;
            break; */

          // rule #20
          case 736:
            if (this.fields[750]["value"] == true) return false;
            else return true;
          case 715:
            if (this.fields[750]["value"] == true) return false;
            else return true;

          // rule #21
          case 125: //23
            if (this.isEmpty(this.fields[141]["value"])) return false;
            else return true;
          /* case 255:
            if (this.fields[142]["value"] == "") return false;
            else return true; */
          case 747: //23
            if (this.isEmpty(this.fields[141]["value"])) return false;
            else return true;
          case 256: //23
            if (this.isEmpty(this.fields[141]["value"])) return false;
            else return true;

          // rule #22
          case 255: //23
            if (
              this.isEmpty(this.fields[107]["value"]) ||
              this.isEmpty(this.fields[141]["value"]) /* rule 21 */
            )
              return false;
            else return true;
          case 124:
            if (this.isEmpty(this.fields[107]["value"])) return false;
            else return true;
          case 156:
            if (this.isEmpty(this.fields[107]["value"])) return false;
            else return true;
          case 154:
            if (this.isEmpty(this.fields[107]["value"])) return false;
            else return true;
          case 158:
            if (this.isEmpty(this.fields[107]["value"])) return false;
            else return true;

          // rule #23
          case 590:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 260:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 591:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 592:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 593:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 594:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;

          
          case 837:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 826:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 832:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 827:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 833:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 829:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 831:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 860:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 861:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 862:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 863:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 864:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 865:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 868:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;

          // rule #35
          case 901:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 902:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 903:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 904:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;

          // rule #36
          case 912:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;
          case 913:
            if (this.isEmpty(this.fields[274]["value"])) return false;
            else return true;

          // rule #24
          case 126:
            if (this.isEmpty(this.fields[198]["value"])) return false;
            else return true;
          case 259:
            if (this.isEmpty(this.fields[198]["value"])) return false;
            else return true;

          // rule #25
          case 629:
            if (this.isEmpty(this.fields[627]["value"])) return false;
            else return true;

          // rule #26
          /* case 505:
            if (this.fields[103]["value"] != "") return false;
            else return true;
          case 519:
            if (this.fields[103]["value"] != "") return false;
            else return true;
          case 502:
            if (this.fields[103]["value"] != "") return false;
            else return true;
          case 521:
            if (this.fields[103]["value"] != "") return false;
            else return true; */
          /* case 333:
            if (!this.isEmpty(this.fields[103]["value"])) return false;
            else return true;
          case 509:
            if (!this.isEmpty(this.fields[103]["value"])) return false;
            else return true;
          case 335:
            if (!this.isEmpty(this.fields[103]["value"])) return false;
            else return true;
          case 511:
            if (!this.isEmpty(this.fields[103]["value"])) return false;
            else return true;
          case 501:
            if (!this.isEmpty(this.fields[103]["value"])) return false;
            else return true;
          case 513:
            if (!this.isEmpty(this.fields[103]["value"])) return false;
            else return true;
          case 503:
            if (!this.isEmpty(this.fields[103]["value"])) return false;
            else return true;
          case 515:
            if (!this.isEmpty(this.fields[103]["value"])) return false;
            else return true;
          case 504:
            if (!this.isEmpty(this.fields[103]["value"])) return false;
            else return true;
          case 517:
            if (!this.isEmpty(this.fields[103]["value"])) return false;
            else return true;
          case 417:
            if (!this.isEmpty(this.fields[103]["value"])) return true;
            else return false; */

          // rule #27
          /* case 523:
            if (!this.isEmpty(this.fields[526]["value"])) return false;
            else return true;
          case 538:
            if (!this.isEmpty(this.fields[526]["value"])) return false;
            else return true;
          case 522:
            if (!this.isEmpty(this.fields[526]["value"])) return false;
            else return true;
          case 540:
            if (!this.isEmpty(this.fields[526]["value"])) return false;
            else return true; */

         /* case 104:
            if (!this.isEmpty(this.fields[526]["value"])) return false; // rule 29
            else return true;
          case 528:
            if (!this.isEmpty(this.fields[526]["value"])) return false; // rule 29
            else return true;
          case 328:
            if (!this.isEmpty(this.fields[526]["value"])) return false; // rule 29
            else return true;
          case 530:
            if (!this.isEmpty(this.fields[526]["value"])) return false; // rule 29
            else return true;
          case 331:
            if (!this.isEmpty(this.fields[526]["value"])) return false; // rule 29
            else return true;
          case 532:
            if (!this.isEmpty(this.fields[526]["value"])) return false; // rule 29
            else return true;
          case 525:
            if (!this.isEmpty(this.fields[526]["value"])) return false; // rule 29
            else return true;
          case 534:
            if (!this.isEmpty(this.fields[526]["value"])) return false; // rule 29
            else return true;
          case 524:
            if (!this.isEmpty(this.fields[526]["value"])) return false; // rule 29
            else return true;
          case 536:
            if (!this.isEmpty(this.fields[526]["value"])) return false; // rule 29
            else return true; */

          /* case 494:
            if (!this.isEmpty(this.fields[526]["value"])) return false;
            else return true; */
          // case 517:
          //   if (
          //     !this.isEmpty(this.fields[526]["value"]) /* ||
          //     !this.isEmpty(this.fields[103]["value"]) rule #26 */
          //   )
          //     return false;
          //   else return true;

          /* case 419:
            if (!this.isEmpty(this.fields[526]["value"])) return false; //rule 29
            else return true; */

          // rule #30
          case 769:
            if (this.isEmpty(this.fields[439]["value"])) return false;
            else return true;

          // rule #29
          case 523:
            if (
              this.fields[545]["value"] == true  /* rule 27  || !this.isEmpty(this.fields[526]["value"])*/
            )
              return false;
            else return true;
          case 538:
            if (
              this.fields[545]["value"] == true  /* rule 27  || !this.isEmpty(this.fields[526]["value"])*/
            )
              return false;
            else return true;
          case 522:
            if (
              this.fields[545]["value"] == true  /* rule 27  || !this.isEmpty(this.fields[526]["value"])*/
            )
              return false;
            else return true;
          case 540:
            if (
              this.fields[545]["value"] == true  /* rule 27  || !this.isEmpty(this.fields[526]["value"])*/
            )
              return false;
            else return true;
          case 505:
            if (
              this.fields[545]["value"] == true 
              || !this.isEmpty(this.fields[103]["value"]) /* rule 26 */
            )
              return false;
            else return true;
          case 519:
            if (
              this.fields[545]["value"] == true 
              || !this.isEmpty(this.fields[103]["value"]) /* rule 26 */
            )
              return false;
            else return true;
          case 502:
            if (
              this.fields[545]["value"] == true 
              || !this.isEmpty(this.fields[103]["value"]) /* rule 26 */
            )
              return false;
            else return true;
          case 521:
            if (
              this.fields[545]["value"] == true 
              || !this.isEmpty(this.fields[103]["value"]) /* rule 26 */
            )
              return false;
            else return true;
          
          // rule #33
          case 852:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 845:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 848:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 846:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 720:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 849:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 648:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 854:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 855:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 856:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 857:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 858:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 859:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 869:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;

          // rule #34
          case 897:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 898:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 899:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 900:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;

          // rule #37
          case 914:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 915:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 917:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;
          case 918:
            if (this.isEmpty(this.fields[271]["value"])) return false;
            else return true;

          // --- Load Options ---
          // Option1
          case 1041:
            if (this.isEmpty(this.fields[1036]["value"])) return false;
            else return true;
          case 1046:
            if (this.isEmpty(this.fields[1036]["value"])) return false;
            else return true;
          case 1051:
            if (this.isEmpty(this.fields[1036]["value"])) return false;
            else return true;
          case 1056:
            if (this.isEmpty(this.fields[1036]["value"])) return false;
            else return true;
          case 1036:
            if (this.isEmpty(this.fields[1036]["value"])) return false;
            else return true;

          // Option2
          case 1037:
            if (this.isEmpty(this.fields[1037]["value"])) return false;
            else return true;
          case 1042:
            if (this.isEmpty(this.fields[1037]["value"])) return false;
            else return true;
          case 1047:
            if (this.isEmpty(this.fields[1037]["value"])) return false;
            else return true;
          case 1052:
            if (this.isEmpty(this.fields[1037]["value"])) return false;
            else return true;
          case 1057:
            if (this.isEmpty(this.fields[1037]["value"])) return false;
            else return true;
  
          // Option3  
          case 1038:
            if (this.isEmpty(this.fields[1038]["value"])) return false;
            else return true;
          case 1043:
            if (this.isEmpty(this.fields[1038]["value"])) return false;
            else return true;
          case 1048:
            if (this.isEmpty(this.fields[1038]["value"])) return false;
            else return true;
          case 1053:
            if (this.isEmpty(this.fields[1038]["value"])) return false;
            else return true;
          case 1058:
            if (this.isEmpty(this.fields[1038]["value"])) return false;
            else return true;

          // Option4
          case 1039:
            if (this.isEmpty(this.fields[1039]["value"])) return false;
            else return true;
          case 1044:
            if (this.isEmpty(this.fields[1039]["value"])) return false;
            else return true;
          case 1049:
            if (this.isEmpty(this.fields[1039]["value"])) return false;
            else return true;
          case 1054:
            if (this.isEmpty(this.fields[1039]["value"])) return false;
            else return true;
          case 1059:
            if (this.isEmpty(this.fields[1039]["value"])) return false;
            else return true;

          // Option5
          case 1082:
            if (this.isEmpty(this.fields[1082]["value"])) return false;
            else return true;
          case 1083:
            if (this.isEmpty(this.fields[1082]["value"])) return false;
            else return true;
          case 1084:
            if (this.isEmpty(this.fields[1082]["value"])) return false;
            else return true;
          case 1085:
            if (this.isEmpty(this.fields[1082]["value"])) return false;
            else return true;
          case 1086:
            if (this.isEmpty(this.fields[1082]["value"])) return false;
            else return true;

          default:
            break;
        }
      } catch (error) {
        return true;
      }
      return true;
    },

    getObjArrWithIdKey(fields) {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      fields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },
    getData() {
      this.mapping = liftingFieldsModel.fieldsMap;

      let params = {};
      params["key"] = this.rid;
      params["clist"] = [
        248, 251, 469, 470, 42, 545, 333, 198, 274, 107, 125, 750, 748, 749,
        746, 339, 754, 474, 475, 473, 472, 526, 103, 627, 439, 142, 558, 780,
        919, 920, 921, 922, 959, 960,
        968, 969,
        973, 974,

        917, 918,

        957, 958,

        372,
        1171
      ];

      for (let header in this.mapping) {
        if (Object.hasOwnProperty.call(this.mapping, header)) {
          for (const subTab in this.mapping[header]["body"]) {
            if (
              Object.hasOwnProperty.call(this.mapping[header]["body"], subTab)
            ) {
              for (const keyRow in this.mapping[header]["body"][subTab][
                "body"
              ]) {
                if (
                  Object.hasOwnProperty.call(
                    this.mapping[header]["body"][subTab]["body"],
                    keyRow
                  )
                ) {
                  for (const key in this.mapping[header]["body"][subTab][
                    "body"
                  ][keyRow]) {
                    if (
                      Object.hasOwnProperty.call(
                        this.mapping[header]["body"][subTab]["body"][keyRow],
                        key
                      )
                    ) {
                      params["clist"].push(
                        this.mapping[header]["body"][subTab]["body"][keyRow][
                          key
                        ].key
                      );
                    }
                  }
                  /* params["clist"].push(
                    this.mapping[header]["body"][subTab]["body"][keyRow].key
                  ); */
                }
              }
            }
          }
        }
      }
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/ligting/query",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          let data = result["body"]["data"];
          let fields = result["body"]["fields"];
          if (data.length) {
            data = data.shift();
            this.fields = data;
            
            if (localStorage.role == "Admin") {
              this.charterer = this.fields[372].value;
            }
 
          }
          this.getAllShipPath();
          console.log(this.mapping);
          for (let header in this.mapping) {
              console.log("mapping1||| " + this.mapping[header].id);
            if (Object.hasOwnProperty.call(this.mapping, header)) {
              for (const subTab in this.mapping[header]["body"]) {
                if (
                  Object.hasOwnProperty.call(
                    this.mapping[header]["body"],
                    subTab
                  )
                ) {
                  for (const keyRow in this.mapping[header]["body"][subTab][
                    "body"
                  ]) {
                    if (
                      Object.hasOwnProperty.call(
                        this.mapping[header]["body"][subTab]["body"],
                        keyRow
                      )
                    ) {
                      for (const key in this.mapping[header]["body"][subTab][
                        "body"
                      ][keyRow]) {
                        if (
                          Object.hasOwnProperty.call(
                            this.mapping[header]["body"][subTab]["body"][
                              keyRow
                            ][key],
                            "key"
                          )
                        ) {
                          let keyForField =
                            this.mapping[header]["body"][subTab]["body"][
                              keyRow
                            ][key].key;
                          if (
                            this.getObjArrWithIdKey(fields)[keyForField].type ==
                              "timestamp" &&
                            data[keyForField].value
                          ) {
                            this.mapping[header].body[subTab].body[keyRow][
                              key
                            ].value = moment(data[keyForField].value)
                              .tz("US/Pacific")
                              .format("MM-DD-yyyy h:mm a");
                          } else if (
                            this.getObjArrWithIdKey(fields)[keyForField].type ==
                              "date" &&
                            data[keyForField].value
                          ) {
                            this.mapping[header].body[subTab].body[keyRow][
                              key
                            ].value = moment(data[keyForField].value).format(
                              "MM-DD-yyyy"
                            );
                          } else {
                            this.mapping[header].body[subTab].body[keyRow][
                              key
                            ].value = data[keyForField].value;
                            // console.log(keyForField + " ||| " +data[keyForField].value);
                          }
                        }
                      }
                      // this.set(this.mapping[header]["body"][subTab]["body"][key], "value", data[
                      //   this.mapping[header]["body"][subTab]["body"][key].key
                      // ].value);
                    }
                  }
                }
              }
              // this.set(this.mapping, header, this.mapping[header]);
            }
          }


          /* this.reportData = result["body"]["data"];
          this.fields = result["body"]["fields"];
          this.allFields = result["body"]["allFields"];
          this.clist = result["body"]["report"]["query"]["fields"];
          this.relationships = result["body"]["relationships"];
          this.gettingReport = false; */
          // this.clients = result;
        })
        .catch((error) => {
          console.log("error", error);
          this.gettingReport = false;
        });
    },

    /**
     * For open pop-up Document
     *  */ 
    openPopUpForAddDocumentTypeCnf(type) {
      this.type = type;
      this.showModalAddDocumentTypeCnf = true;
    },
    openPopUpForAddDocumentTypeFob(type) {
      this.type = type;
      this.showModalAddDocumentTypeFob = true;
    },
    showStatusAddDocumentTypeCnfPopUp(status) {
      this.showModalAddDocumentTypeCnf = status;
    },
    showStatusAddDocumentTypeFobPopUp(status) {
      this.showModalAddDocumentTypeFob = status;
    },

    getDataTaskFields() {
      QuickbaseRepository.getTableInfo(this.masterTableId)
        .then((result) => {
          this.allFields = result["data"]["body"]["allFields"];
          this.getDataForDropDownFields();

          console.log(this.objAllFields[3]);
        })
        .catch((error) => console.log("error", error));
    },

    async getGoogleDriveId() {
      await new Promise((resolve) => {
        setTimeout(() => {
          this.googleDriveId = this.mapping[3]?.body?.[0]?.body?.[0]?.[2]?.value;
          // this.googleDriveId = "1yMOh8ocaMUC0AGzHYQVaQmX3hkKLGTZL";

          resolve();
        }, 3000);
      });

      this.getGoogleDocuments();
    },

    async getGoogleDocuments() {
      fetch(`https://api.www.newtoncommoditiesscripts.com/drive/list-files.php?folderId=${this.googleDriveId}`)
        .then((response) => response.json())
        .then((result) => {
          this.allDocuments = result.files
          
          this.insertDocInPreview();
          // console.log("allDocuments", this.allDocuments);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },

    insertDocInPreview() {
      if(!this.mapping[3].body[0].body[2][1].value && this.allDocuments.length) {
        const firstSearch = ['invoice', 'fi', 'ifi'];
        const secondSearch = ['ffs', 'ffi', 'final freight invoice'];

        const primaryMatches = this.allDocuments.filter(doc => 
          firstSearch.some(word => 
            new RegExp(`\\b${word}\\b`, 'i').test(doc.name)
          )
        );

        if (primaryMatches.length === 1) {
          this.mapping[3].body[0].body[2][1].value = primaryMatches[0].previewUrl; 
        } else if (primaryMatches.length > 1) {
            const secondaryMatches = primaryMatches.filter(doc =>
              secondSearch.some(word =>
                new RegExp(`\\b${word}\\b`, 'i').test(doc.name)
              )
            );

            if (secondaryMatches.length === 0) {
              this.mapping[3].body[0].body[2][1].value = primaryMatches[0].previewUrl;
            } else {
              this.mapping[3].body[0].body[2][1].value = secondaryMatches[0].previewUrl;
            }
        }
      }
    },

    /**
     * Collect data for send to quickbase
     */
    sendToQB() {
      if (!this.sendingToQB) {
        this.sendingToQB = true;
        let prop = {};
        prop["to"] = this.masterTableId;
        prop["data"] = [];

        let dataTmp = {};
        dataTmp[3] = this.rid;
        if (this.fields[372]?.value) {
          dataTmp[372] = this.fields[372].value;
        }
        if (this.fields[1199]?.value) {
          dataTmp[1199] = this.fields[1199]["value"];
        }
        this.mapping.forEach((tabs) => {
          tabs.body.forEach((subTubs) => {
            subTubs.body.forEach((row) => {
              row.forEach((field) => {
                if (Object.hasOwnProperty.call(field, "key")) {
                  if (
                    // field.value != "" &&
                    // field.value != null &&
                    (this.objAllFields[field.key].mode == "" &&
                      this.objAllFields[field.key].fieldType != "dblink") /*  &&
                      this.objAllFields[field.key].fieldType !=
                        "text-multiple-choice" */ ||
                    //&& this.objAllFields[field.key].doesDataCopy
                    this.objAllFields[field.key].fieldType == "recordid"
                  ) {
                    if (this.objAllFields[field.key].fieldType == "date") {
                      if (field.value != "" && field.value != null) {
                        dataTmp[field.key] = moment(field.value).format(
                          "YYYY-MM-DD"
                        );
                      } else {
                        dataTmp[field.key] = "";
                      }
                    } else if (
                      this.objAllFields[field.key].fieldType == "timestamp"
                    ) {
                      if (field.value != "" && field.value != null) {
                        dataTmp[field.key] = moment(field.value).format(
                          //2019-12-18T12:00:00 YYYY-MM-DD h:mm a
                          "YYYY-MM-DDTHH:mm:ss"
                        );
                      } else {
                        dataTmp[field.key] = "";
                      }
                    } else {
                      dataTmp[field.key] = field.value;
                    }
                  }
                }
              });
              /* console.log(row);
              if (Object.hasOwnProperty.call(row, "key")) {
                if (
                  (row.value != "" &&
                    row.value != null &&
                    this.objAllFields[row.key].mode == "" &&
                    this.objAllFields[row.key].doesDataCopy &&
                    this.objAllFields[row.key].fieldType !=
                      "text-multiple-choice") ||
                  this.objAllFields[row.key].fieldType == "recordid"
                ) {
                  dataTmp[row.key] = row.value;
                }
              } */
            });
          });
          /* if (el.value != "") {
            dataTmp[el.key] = el.value;
          } */
        });
        prop["data"].push(dataTmp);
        console.log("Seve data:");
        console.log(prop);
        this.saveDataForQB(prop);
      }
      // this.$emit('showStatus', false);
    },

    async saveDataForQBWithoutClosePopUp(params) {
      QuickbaseRepository.pushDataToQB(params)
        .then((result) => {
          console.log(result);
          // this.clients = result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    handleSave(isSubmit) {
      let allFieldsFilled = true;

      this.requiredFields.forEach((elem) => {
        if (this.fields[elem]?.value == null || this.fields[elem]?.value === "") {
          this[`field${elem}`] = false;
          allFieldsFilled = false;
        }
      });

      if (!allFieldsFilled) {
        this.showRequiredFieldsPopUp = true;

        setTimeout(() => {
          this.showRequiredFieldsPopUp = false;
          this.selectedSwitcher = 1
        }, 3000);
      }

      else if (allFieldsFilled && !this.sendingToQB) {
        this.isSubmitAction = isSubmit;
        this.sendToQB();
      }
    },

    /**
     * Save all data to quickbase
     */
    async saveDataForQB(params) {
      console.log("TestParams", params)
      QuickbaseRepository.pushDataToQB(params)
        .then((result) => {
          console.log("RESULT UPDATED", result);

          if (this.isSubmitAction) {
            this.openPopUpForUpdateRecord(result.status)

            setTimeout(() => {
              this.$router.push("/").then(() => {
                window.location.reload();
              });
            }, 4500)

          } else {
            this.openPopUpForUpdateRecord(result.status)
            setTimeout(() => {window.location.reload();}, 3000)
          }

          this.$emit("showStatus", false);
          this.$emit("updateRecord", this.rid);

          // this.clients = result;
        })
          .catch((error) => {
            console.log("error", error);
            this.openPopUpForUpdateRecord(error)
          })
          .finally(() => {
            this.sendingToQB = false;
            this.isSubmitAction = null;
          });
    },

    openPopUpForUpdateRecord(serverResponse) {
      if(serverResponse === 200) {
        this.updatedData = true
      } else {
        this.updatedData = false
      }

      this.showUpdateRecordPopUp = true;

      setTimeout(() => {
        this.showUpdateRecordPopUp = false;
      }, 3000);
    },

    updateETALoadPort(rid) {
      LiftingRepository.updateETALoadPort(rid)
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    updateETCLoadPort(rid) {
      LiftingRepository.updateETCLoadPort(rid)
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    updateCompleteCurrentTask(rid) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: localStorage.token,
        }),
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN +
          "/v1/qb/button/completeCurrentTask/record/" +
          rid,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getGoogleMapCenter() {
      // this.fields[920]["value"]
      let  center = { lat: 0, lng: 0 };
      if(this.fields[920]["value"] && this.fields[922]["value"]) {
        center = { lat: Number(this.fields[920]["value"]), lng: Number(this.fields[922]["value"])};
      }
      return center;
    },

    showGoogleMap() {
      if(this.fields[42].value == "8. Final Freight Paid" || this.fields[42].value == "9. Closed / Archived") {
        return false;
      }
      return true;
    },
    
    getGoogleMapMarker() {
      // this.fields[920]["value"]
      let  center = {};
      if(this.fields[920]["value"] && this.fields[922]["value"]) {
        center = { lat: Number(this.fields[920]["value"]), lng: Number(this.fields[922]["value"])};
      }
      return center;
    },
    async getAllShipPath() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: { 
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.token, 
        },
        // body: JSON.stringify({
        //   token: localStorage.token,
        // }),
      };

      fetch(
        // process.env.VUE_APP_API_DOMAIN + "/v1/qb/ligting/query",
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/vessel/" + this.fields[95].value + "/path",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          let data = result["body"];
          if(data.length > 0) {
            this.shipPaths = data;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getShipPath(index) {
      if(this.shipPaths.length > index) {
        const flightPlanCoordinates = [
        ];
        flightPlanCoordinates.push(this.shipPaths[index]);
        flightPlanCoordinates.push(this.shipPaths[index+1]);

        const lineSymbol = {
          path: "M 0,-1 0,1",
          strokeOpacity: 1,
          scale: 4,
        };

        const flightPath = {
          path: flightPlanCoordinates,
          geodesic: true,
          strokeColor: "#008035",
          // strokeOpacity: 1.0,
          strokeOpacity: 0,
          // strokeWeight: 2,
          icons: [
            {
              icon: lineSymbol,
              offset: "0",
              repeat: "20px",
            },
          ],
        };
        return flightPath;
      }
      return null;
    },

    getStartDateValue(value) {
      if (Object.hasOwnProperty.call(value, "startDateId")) {
        if(!this.fields[value.startDateId]["value"]) {
          if(this.fields[value.startAltDateId]["value"]) return moment(this.fields[value.startAltDateId]["value"]);
          return null;
        }
        return moment(this.fields[value.startDateId]["value"]);
      }
      return null;
    },
    getEndDateValue(value) {
      if (Object.hasOwnProperty.call(value, "endDateId")) {
        if(!this.fields[value.endDateId]["value"]) {
          if(this.fields[value.endAltDateId]["value"]) return moment(this.fields[value.endAltDateId]["value"]);
          return null;
        }
        return moment(this.fields[value.endDateId]["value"]);
      }
      return null;
    },
    canShowFieldForTab(tabTitle, field) {
      if (!field.toggleShow) {
        return true;
      }
      if (this.showHolds[tabTitle] && this.showHolds[tabTitle] !== false) {
        return true;
      }

      if (this.showHolds[tabTitle] === false && field.toggleShow) {
        return false;
      }

      return true;
    },
    toggleHolds(tabTitle) {
      if(!this.showHolds[tabTitle]) {
        this.showHolds[tabTitle] = false;
      }

      this.showHolds[tabTitle] = !this.showHolds[tabTitle];
    },
    canShowShipFieldForTab(field) {
      if (field.toggleShip === undefined) {
        return true;
      }

      return this.showShipFields ? field.toggleShip : !field.toggleShip;
    },
    toggleShip() {
      this.showShipFields = !this.showShipFields;
      this.resetShipFields();
    },
    resetHoldsAndShips() {
      this.showShipFields = true;
      // this.resetShipFields();

      for(const key in this.showHolds) {
        this.showHolds[key] = false;
      }
    },
    shouldShowButton() {
      const selectedValue = this.mapping[6]?.body?.[0]?.body?.[0]?.[0]?.value;
      const cleanValue = selectedValue?.split('. ')[1]?.trim();
      return cleanValue === 'Nominated' || cleanValue === 'Loading';
    },
    tabToggle() {
      if (!this.shouldShowButton()) {
        if (Number(this.selectedSwitcher) === 0 || Number(this.selectedSwitcher) === 4) {
          this.selectedSwitcher = 1;
        }
      }

      this.resetHoldsAndShips();
    },


    findObjectByKey(array, targetKey) {
      for (const item of array) {
        if (item.key === targetKey) {
          return item;
        }
        if (Array.isArray(item.body)) {
          const result = this.findObjectByKey(item.body, targetKey);
          if (result) {
            return result;
          }
        }
        if (Array.isArray(item)) {
          const result = this.findObjectByKey(item, targetKey);
          if (result) {
            return result;
          }
        }
      }
      return null;
    },
    resultYear() {
      const currentYear = new Date().getFullYear();
      let ageObject = this.findObjectByKey(this.mapping, 1186);
      let builtObject = this.findObjectByKey(this.mapping, 1176);

      ageObject.value = builtObject.value ? currentYear - builtObject.value : '';
    },
    resetShipFields() {
      if (!this.showShipFields) {
        let keysForValueDeleting = [1171, 1172, 1173, 1174, 1175, 1176, 1177, 1186, 1223, 1224];
        for (let key of keysForValueDeleting) {
          let object = this.findObjectByKey(this.mapping, key);
          object.value = '';
        }
      } else {
        let object = this.findObjectByKey(this.mapping, 95);
        object.value = '';
      }
    },

    handleInitialFreightInput(event) {
      if (!this.fields[1222].value && event.target.value) {

        console.log("valueEvent", event.target.value)

        // let object = this.findObjectByKey(this.mapping, 1222);
        // object.value = 'testText';

        // this.fields[1222].value = (new Date()).toString();

      }

      // console.log("valueEvent", event.target.value)
      // console.log("value1222", this.fields[1222].value)
    },
    
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* * {
  box-sizing: border-box;
} */
* {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
}

.switcher-content * {
  font-size: 14px;
  /*min-width: 240px;*/
}
.tables-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-top: 20px;
}
.tables-items {
  display: flex;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.modal__mask {
  display: flex;
  flex-grow: 1;
  overflow-y: hidden;
  flex-direction: column;
}

.modal__wrapper {
  max-height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.modal__container {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal__container::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.modal__container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.modal__container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.modal__container-loader {
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 1919px) {
  .google-docs-wrap .docs-preview iframe {
    width: 880px;
    height: 400px;
  }

  .google-docs-wrap .docs-preview {
    right: -910px;
  }
}

@media screen and (max-width: 1680px) {
  .modal__container {
    /* overflow-y: scroll; */
    /* width: 300px; */
    /* width: 1070px; */
  }
}

@media screen and (max-width: 1200px) {
  .modal__container {
    /* overflow-y: scroll; */
    /* width: 300px; */
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    width: 100%;
    /* max-width: 800px; */
  }
}

.modal__header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal__header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  /* border-bottom: 1px solid #d3dfed; */
  background: #F6FAFF;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  flex: 0 0 auto;
}

.modal__header-content {
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-right: 40px;
  align-items: center;
}

.report-title {
  font-size: 26px;
  font-weight: 600;
}

.modal__header-left {
  display: flex;
  gap: 20px;
}

.modal__header-panel {
  display: flex;
}

.modal__title {
  padding: 5px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #2d3757;
  max-width: 70%;
  justify-items: center;
  text-align: left;
  flex-grow: 1;
}

.modal__close {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url(@/assets/close.svg) no-repeat center center;
}

.modal__body {
  flex: 1 1 auto;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
}

.modal__default-button {
  float: right;
}

.modal__footer {
  display: flex;
  justify-content: space-between;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #2d3757;
  padding-left: 30px;
  padding-right: 30px;
  flex: 0 0 auto;
}

.modal__close-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.modal__close-button:hover {
  background-color: #50629b;
  color: white;
}

.modal__close-button:hover .close-button__img {
  filter: invert(100%) brightness(1000%);
}

.close-button__img {
  width: 16px;
  height: 16px;
  background: url(@/assets/btn-close.svg) no-repeat center center;
  margin-right: 10px;
}

.modal__save-button {
  background: #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  color: #ffffff;
  margin-left: 15px;
  cursor: pointer;
  border: 1px solid transparent;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 116px;
}
.modal__delete-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: rgba(218, 52, 0, 1)
}

.modal__delete-button:hover {
  color: #fff;
  background: rgba(218, 52, 0, 1);
}

.modal__save-button:hover {
  background-color: #50629b;
  border: 1px solid transparent;
  outline: none;
}
.modal__submit-button {
  background-color: #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  color: #ffffff;
  margin-left: 15px;
  cursor: pointer;
  border: 1px solid transparent;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 116px;
}
.modal__submit-button:hover {
  background: #50629b;
  border: 1px solid transparent;
  outline: none;
}
.save-button__body {
  display: flex;
  align-items: center;
}

.save-button__img {
  width: 16px;
  height: 16px;
  background: url(@/assets/btn-save.svg) no-repeat center center;
  margin-right: 10px;
}

.preloader {
  display: flex;
  justify-content: center;
}

.document-report-wrap {
  /* max-width: 1100px; */
  width: 100%;
}

.report-body::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.report-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.report-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.item-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.item-modal__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2d3757;
}

.item-modal__img {
  margin-left: 12px;
  height: 20px;
  width: 20px;
  background: url(@/assets/share.svg) no-repeat center center;
}

.switch-bar {
  display: flex;
  flex: 0 0 auto;
  background: #F6FAFF;
  padding: 0 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid #D3DFED;
}

.switch-bar__item {
  /* flex: 1 1 auto; */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  cursor: pointer;
  margin-right: 15px;
  border: 1px solid transparent;
}

.switch-bar__item:hover {
  background: #ffffff;
  border: 1px solid #5D88BE;
  border-radius: 2px;
}

.switch-bar__item_active {
  /* border-bottom: 2px solid #2d3757; */
  
  background: #D3DFED;
  border: 1px solid #5D88BE;
  border-radius: 2px;
}

.switcher-content {
  flex: 1 1 auto;
  max-height: 100%;
  overflow-y: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.switcher-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f9f9fd;
}

.switcher-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.switcher-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.switcher-content__choose {
  /* display: flex; */
  /* flex-wrap: wrap; */
}

.switcher-content__sub-tab-body {
  /*overflow-y: auto;*/
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* align-items: end; */
  /* overflow: auto; */
  /* overflow-x: auto; */
}

.disabled {
  background: #eee;
}

.switcher-content__sub-tab-title {
  color: #333;
  font-size: 18px;
  font-weight: bold;
  border-bottom: #2d3757 1px solid;
}

.switcher-content__item {
  box-sizing: border-box;
  padding: 20px 20px;
  width: 50%;
  min-width: 100px;
}

.switcher-content__item_100 {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 20px;
}

.switcher-content__item_25 {
  box-sizing: border-box;
  width: 25%;
  padding: 20px 20px;
}

.switcher-content__title {
  padding: 8px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #6c6f7d;
}

.field-title {
  padding: 8px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #6c6f7d;

  display: flex;
  align-items: center;
}

.field-title__btn-log {
  cursor: pointer;
  margin-right: 5px;
  border-radius: 50px;
  background:  #2d3757;
  color: #fff;
  padding: 2px 7px;
}

.switcher-content__data-input {
  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 12px;
  /* width: 100%; */
  font-size: 16px;
  font-family: "Lato";
}

.switcher-content__data-input.checkbox {
  width: auto;
}

.switcher-content__text-data-textarea {
  /* box-sizing: content-box; */
  display: flex;
  box-sizing: border-box;
  vertical-align: bottom;
  /* box-sizing: initial; */
  /* display: table-column; */
  padding: 10px;
  /* word-wrap: break-word; */
  white-space: pre;
  /* padding-bottom: 0; */
  /* width: 100%; */
  font-size: 16px;
  font-family: "Lato";
}

.switcher-content__text-data-textarea.disabled {
  /* resize: none; */
  outline: none;
  /* border: none; */
  /* border-left: 1px solid #777; */
  /* border-right: 1px solid #777; */
  /* border-bottom: 1px solid #777; */
  /* margin: 0; */
  /* padding: 0; */
  /* position: absolute; */
  /* top: 40px; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0;  */
  /* width:-moz-availabel; height:100%;  */
  resize: none;
}

.url-btn {
  display: block;
  border: 1px solid #2d3757;
  background: #2d375715;
  text-decoration: none;
  border-radius: 5px;
  padding: 5px 15px;
  cursor: pointer;
  text-align: center;
}
.url-btn:hover {
  color: #fff;
  background: #333;
}

.switcher-content__sub-tab-table {
  /* width: 100%; */
  position: relative;
}

.switcher-content__sub-tab-table td {
  padding: 5px;
  border: 5px solid transparent;
  vertical-align: top;
}

/* .switcher-content__sub-tab-table td * {
  box-sizing: border-box;
  max-width: 100%;
} */

.switcher-content__sub-tab-table td .field-title {
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
}


.switcher-content__sub-tab-table td .switcher-content__data-input {
  box-sizing: border-box;
  /* max-width: 100%; */
  width: 240px;
  max-width: 240px;
}

.switcher-content__sub-tab-table td .switcher-content__data-multiselect {
  box-sizing: border-box;
  /* max-width: 100%; */
  width: 240px;
  max-width: 240px;
}

.vessel-fields-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.vessel-fields-wrap span {
  width: 33%;
  display: flex
}

.vessel-field-6 {
  justify-content: flex-start;
}

.vessel-field-43 {
  justify-content: center;
}

.vessel-field-30 {
  justify-content: flex-end;
}

.switcher-content__sub-tab-table td .switcher-content__data-multiselect-document-filter {
  box-sizing: border-box;
  /* max-width: 100%; */
  width: 100%;
  max-width: 320px;
}


.switcher-content__sub-tab-table td .switcher-content__data-input.checkbox {
  width: auto;
}

.switcher-content__text-data-textarea {
  min-width: 240px;
  border: 1px solid #d3dfed;
  border-radius: 2px;
}

.v-toolbar {
  z-index: 20 !important;
}
.multiselect--active {
  z-index: 20 !important;
}
.multiselect__content-wrapper {
  z-index: 20 !important;
}


.btn-log {
}

.btn-log__body {
  display: none;
  width: max-content;
  position: absolute;
  background: #2d3757;
  color: #fff;
  z-index: 5;
  padding: 5px;
  left: 0;
  bottom: 30px;
  border-radius: 2px;
  box-shadow: -1px -1px 10px 0px #2d375780;
  border: 1px solid #d3dfed;
}

.btn-log:hover .btn-log__body {
  display: block;
}

td.wrap-new-ship-button {
  vertical-align: bottom;
}
.new-ship-button {
  width: 135px;
  padding: 11px 24px;
  margin-left: 25px;
  background: #2d3757;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid transparent;
  box-sizing: border-box;
}
.new-ship-button:hover {
  background-color: #50629b;
  border: 1px solid transparent;
  outline: none;
}

.google-docs-wrap .switcher-content__data-multiselect {
  margin-bottom: 20px;
}

.docs-preview iframe {
  width: 1270px;
  height: 1000px;
  border: 1px solid #d3dfed;
}

.docs-preview {
  position: absolute;
  right: -1300px;
  top: 28px;
}

.required-fields-star{
  color: #da3400;
  font-size: 18px;
  margin-left: 2px;
}

.required-fields {
  position: relative;
}

.message-text {
  position: absolute;
  left: 0;
  bottom: -17px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #da3400;
}

</style>

<style>

.vessel-name .multiselect-dropdown {
  min-width: 450px;
}

.rich-text__484 div a {
  padding: 8px 8px;
  text-decoration: none;
  /* color: #6c6f7d; */

  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  cursor: default;
}

.dp-custom-input {
  font-size: 14px !important;
  width: auto !important;
}
.v-toolbar {
  z-index: 20 !important;
}
.multiselect--active {
  z-index: 20 !important;
}
.multiselect__content-wrapper {
  z-index: 20 !important;
}


.multiselect-tags-search-wrapper {
  display: none !important;
}

.btn-apply {
  /* display: flex; */
  margin-left: 20px;
  /* margin-top: 1px; */
}

.btn-apply__body {
  /* background: rgb(106, 141, 255); */
  color: #fff;
  /* font-size: 1.25em; */
  padding: 10px 30px;
  margin-right: 20px;
  cursor: pointer;

  background: #2d3757;
  border-radius: 2px;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 19px; */

  color: #ffffff;
}

.report-table__item-text-multiple-choice-wrap {
  min-width: 240px;
}

.document-report-wrap {
  max-width: 100%;
  width: 100%;
}

/* .report-body {
  width: 1100px;
}

@media screen and (max-width: 1200px) {
  .report-body {
    width: 700px;
  }
} */


.google-drive {
  display: flex;
  align-items: center;
}

.google-drive__left-img {
  width: 16px;
  height: 16px;
  background: url(@/assets/link.svg) no-repeat center center;
  margin-right: 8px;
}

</style>

<style scoped>
.dp__input_wrap .dp__input {
  font-size: 14px;
  padding: 8px 12px;
  padding-left: 35px;
  border: 1px solid #d3dfed !important;
  border-radius: 2px !important;
}

.multiselect {
  height: 43px !important;
  border: 1px solid #d3dfed !important;
  border-radius: 2px !important;
}
</style>

<style scoped src="@vueform/multiselect/themes/default.css"></style>

