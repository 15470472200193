import ApiHelper from "@/api/ApiHelper"

export default {
    async reportById(table, reportId, filter) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },

        };

        let data = JSON.stringify({
            filter: filter,
            token: localStorage.token,
        })

        let url = "/v1/qb/reports/" + reportId + "/run?table_id=" + table;
        return await ApiHelper.getResult(url, data, headers);

        // return Api.post("/v1/qb/reports/" + reportId + "/run?table_id=" + table, 
        // data, headers);
    },

    async getFilterData() {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + localStorage.token, },
        };
        console.log(headers);

        let data = JSON.stringify({
            token: localStorage.token,
        })
        let url = "/v1/qb/filter";
        return await ApiHelper.getResult(url, data, headers);
    },

    async updateETCLoadPort(rid) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },

        };

        let data = JSON.stringify({
            token: localStorage.token,
        })

        let url = "/v1/qb/button/etc/record/" + rid;
        return await ApiHelper.getResult(url, data, headers);

        // return Api.post("/v1/qb/reports/" + reportId + "/run?table_id=" + table, 
        // data, headers);
    },

    async updateETALoadPort(rid) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },

        };

        let data = JSON.stringify({
            token: localStorage.token,
        })

        let url = "/v1/qb/button/eta/record/" + rid;
        return await ApiHelper.getResult(url, data, headers);

        // return Api.post("/v1/qb/reports/" + reportId + "/run?table_id=" + table, 
        // data, headers);
    },

    async deleteLifting(rid) {
        var headers = {
            method: "DELETE",
            redirect: "follow",
            headers: { 
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.token, 
            },
        };

        let data = JSON.stringify({
            token: localStorage.token,
        });

        let url = `/v1/lifting/${rid}`;

        return await ApiHelper.getResult(url, data, headers);
    },

    async updateShakeyETA(rid, value) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },

        };

        let data = JSON.stringify({
            token: localStorage.token,
            value: value,
        })

        let url = "/v1/qb/button/shakey/eta/record/" + rid;
        return await ApiHelper.getResult(url, data, headers);
    },
}