import * as Mutation from './mutation-types'
import Lifting from '@/api/Lifting';

export default {
  getReport ({ commit }, {table, reportId, filter}) {
    console.log(commit);
    console.log("getLiftingReport" + reportId);
    Lifting.reportById(table, reportId, filter).then(response => {
      console.log("response getLiftingReport" + reportId);
      console.log(response);
      // commit(Mutation.SET_HOLIDAYS_CLIST, response.data["body"]["data"])
      // commit(Mutation.SET_HOLIDAYS_REPORT_DATA, response.data["body"]["data"])
      return commit(Mutation.ADD_REPORT, {body: response.data["body"], reportId: reportId})
    });
  },
  updateReportRecordValue({ commit }, {reportID, newData}) {
    commit(Mutation.UPDATE_REPORT_RECORD_VALUE, {reportID, newData});
  }
  /* createEvent ({ commit }, event) {
    return commit(Mutation.CREATE_EVENT, event)
  },
  updateEvent ({ commit }, updatedEvent) {
    return commit(Mutation.UPDATE_EVENT, updatedEvent)
  },
  deleteEvent ({ commit }, eventId) {
    return commit(Mutation.DELETE_EVENT, eventId)
  },
  setweekendsVisible ({ commit }, enabled) {
    return commit(Mutation.SET_WEEKENDS_ENABLED, enabled)
  },
  setRef ({ commit }, event) {
    return commit(Mutation.CREATE_EVENT, event)
  },
  getCount ({ commit }) {
    return commit(Mutation.COUNT_EVENT)
  }, */
}
